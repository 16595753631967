import { request, POST, GET } from 'utils/apiUtils'
import * as actionTypes from './actionTypes'

export const getAudiencePreview = (bulkEmailId, ruleSet) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setPreviewLeads(payload.leads))
      if (payload.meta) {
        dispatch(setTotalCount(payload.meta.total_count))
      }
      dispatch(setLoading(false))
    }

    let url = 'mass_lead_emails/' + bulkEmailId + '/audience_preview'
    return request(url, POST, ruleSet, onSuccess, onFailure)
  }
}

export const getPreviewLeads = (ruleSet) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setPreviewLeads(payload.leads))
      if (payload.meta) {
        dispatch(setTotalCount(payload.meta.total_count))
      }
      dispatch(setLoading(false))
    }

    return request('marketing_group_audience_preview', POST, ruleSet, onSuccess, onFailure)
  }
}

export const getGroupLeads = (groupId) => {
  return (dispatch) => {
    dispatch(setLoading(true))

    function onFailure (payload) {
      dispatch(setLoading(false))
    }

    function onSuccess (payload) {
      dispatch(setPreviewLeads(payload.leads))
      if (payload.meta) {
        dispatch(setTotalCount(payload.meta.total_count))
      }
      dispatch(setLoading(false))
    }
    let url = 'marketing_groups/' + groupId + '/list_leads'
    return request(url, GET, null, onSuccess, onFailure)
  }
}

function setLoading (loading) {
  return {
    type: actionTypes.SET_LOADING,
    loading
  }
}

export function setPreviewLeads (previewLeads) {
  return {
    type: actionTypes.SET_PREVIEW_LEADS,
    previewLeads
  }
}

export function setTotalCount (totalCount) {
  return {
    type: actionTypes.SET_TOTAL_COUNT,
    totalCount
  }
}

export function reset () {
  return {
    type: actionTypes.RESET
  }
}
